import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";
import ReorderIcon from "@material-ui/icons/Reorder";

function NavBar() {
  const location = useLocation();
  const [expandNavbar, setExpandNavbar] = useState(false);

  useEffect(() => {
    setExpandNavbar(false)
  }, [location]);

  function handleResize() {
    if (window.innerWidth >= 600) {
      setExpandNavbar(false);
    }
    else if (window.innerWidth < 600 && expandNavbar) {
      setExpandNavbar(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);


  return (
    <div className="navbar" id={expandNavbar ? "open" : "close"}>

      <div className="header">
        <div className="left">
          <a div className="cta">
            <Link to="/"><p>  Henry Gao </p></Link>
          </a>
        </div>

      </div>
      <div className="toggleButton">
        <button
          onClick={() => {
            setExpandNavbar((prev) => !prev);
          }}
        >
          <ReorderIcon />
        </button>
      </div>
      <div className="links">
        <Link to="/"> Home </Link>
        <Link to="/experience"> Experience </Link>
        <Link to="/blogs"> Blogs </Link>
      </div>
    </div>
  );
}

export default NavBar;
