import React from 'react';
import '../styles/Blog.css';
import dataFile from '../assets/data.json';
import BlogPost from "../components/BlogPost";

function Blog() {
  return (
    <div className="blog-posts">
      <h1> Blogs</h1>
      <div className="bloglist">
        {dataFile.map((item, idx) => {
          return (
            <div key={idx} className="blogItem">
              <BlogPost id={idx} name={item.title} description={item.description} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Blog;

