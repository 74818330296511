import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2017 - 2021"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Shaker High School
          </h3>
          <p>Served as president of the math club, treasurer of the Russian club, and was an active member of the coding club and robotics team. </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2021 - 2025"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Cornell University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>
          <p> Majoring in Computer Science and Math.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2022"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Rensselaer Polytechnic Institute
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Troy, New York
          </h4>
          <p>
            Worked with Professor Tong Zhang, implemented a user-space in-memory object store, using an indexing and memory management
            approaches similar to that of zsmalloc.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021-2023"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Cornell Autoboat
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Ithaca, New York
          </h4>
          <p>Worked with CS subteam and the Electrical subteam to establish connectivity using ROS (Robot Operating System), and also designed and developed a user interface (UI) for testing pathfinding algorithms.</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2021 - 2023"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Cornell Bowers CIS
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Ithaca, New York
          </h4>
          <p>
            Worked under Walker White and Anne Bracy as a consultant for Cornell Introduction to Python, a course with 700+ students. Helped students understand recursion, diagram heap and stack memory, debug, and use Cornell GUI package.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2023"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            MVP Healthcare
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Schenectady, New York
          </h4>
          <p>
            Product Portfolio Intern.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2023 - 2024"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Cornell Bowers CIS
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Ithaca, New York
          </h4>
          <p>
            Worked under Anke Van Zuylen as a Teacher's Assistant for Cornell Introduction to Algorithms, a course with 400+ students.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
