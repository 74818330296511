/**
 * This is the individual blog in the blog page;
 * also deals with redirecting blog to another page on click
 *  */
import { useNavigate } from "react-router-dom";
function ProjectItem({ description, name, id }) {
  const navigate = useNavigate();
  return (
    <div
      className="projectItem"
      onClick={() => {
        navigate("/blogPost/" + id);
      }}
    >
      <h2>  {name} </h2>
      <p className="description">{description}</p>

    </div>
  );
}

export default ProjectItem;

