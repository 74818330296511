import "../styles/Home.css";
import React from 'react';
import henry from '../assets/henry.jpeg';

const Header = () => (
  <div className="home">
    <div className="svg-background"></div> {/* empty div for the background */}
    <div className="home-content">
      <h2 className="Introduction">Hi my name is</h2>
      <h1 className="Name">Henry Gao</h1>
      <p>
        I am a Junior studying mathematics and computer science at Cornell University. My interests broadly span game design, systems programming, and algorithm analysis.
      </p>
    </div>
    <div className="home-image">
      <img src={henry} alt="Henry" />
    </div>
  </div>
);

export default Header;
