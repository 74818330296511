/** This is the blog post page */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dataFile from '../assets/data.json';
import "../styles/DisplayBlog.css";

function DisplayBlog() {
  const { id } = useParams();
  const project = dataFile[id];
  const [isLaptop, setIsLaptop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 6);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="project">
      <h1>{project.title}</h1>
      <div className="skills">{project.skills !== "None" && project.skills}</div>

      {isLaptop && (
        <div className="buttons">
          {project.windows && (
            <a href={project.windows} download>
              <button type="button">Windows</button>
            </a>
          )}
          {project.mac && (
            <a href={project.mac} download>
              <button type="button">macOS</button>
            </a>
          )}
        </div>
      )}

      {project.article.map((item, idx) => (
        <div key={idx}>
          {(item.image) && <img src={item.image} alt="" />}
          <p>{item.paragraph}</p>
        </div>
      ))}
    </div>
  );
}

export default DisplayBlog;
