import React from "react";
import { Link } from "react-router-dom";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";

import "../styles/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p> Designed by Henry Gao &copy; 2023</p>
      <div className="socialMedia">
        <Link to="http://github.com/Henrygao19">   <GitHubIcon /> </Link>
        <Link to="https://www.linkedin.com/in/henry-gao-353a1621b/">   <LinkedInIcon /> </Link>
        <Link to="mailto:henrygao13@gmail.com">   <EmailIcon /> </Link>
      </div>
    </footer>
  );
}

export default Footer;
